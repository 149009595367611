const SlideArrow = () => {
  return (
    <svg width="9" height="13" viewBox="0 0 9 13" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path fillRule="evenodd" clipRule="evenodd" d="M4.82993 11.8966C5.61098 12.6777 6.87731 12.6777 7.65835 11.8966C8.4394 11.1156 8.4394 9.84926 7.65835 9.06821L4.83028 6.24013L7.6564 3.414C8.43745 2.63296 8.43745 1.36663 7.6564 0.585577C6.87535 -0.195472 5.60902 -0.195472 4.82797 0.585577L0.619349 4.7942C0.608573 4.80451 0.597885 4.81497 0.587286 4.82557C0.514426 4.89843 0.448362 4.97551 0.389095 5.05603C-0.189068 5.8388 -0.123654 6.94766 0.585333 7.65665C0.612644 7.68396 0.640548 7.71031 0.669003 7.73571L4.82993 11.8966Z" fill="currentColor" />
      </g>
    </svg>
  );
}

export default SlideArrow;